/* Colors. */
$first-blue: #0066B3;
$first-red: #ED1C24;
$first-gray: #9A989A;
$first-black: #231F20;

$first-purple: #662D91;
$first-green: #00A651;
$first-orange: #F57E25;
$first-light-blue: #009CD7;

$first-light-gray: #E5E5E5;

$first-ma-dark-blue: #003479;
$first-ma-dark-gray: #2c2a2c;

// Colors names sourced from http://chir.ag/projects/name-that-color.
$amber: #ffbf00;
$black: #000000;
$red: #ff0000;
$cape-honey: #fee5ac;
$meteor: #d07d12;
$eucalyptus: #278a5b;
$parsley: #134f19;
$bush: #0d2e1c;
$gallery: #efefef;
$havelock-blue: #5590d9;
$dark-pumpkin: #b1610b;
$light-gray: #d3d3d3;
$dove-gray: #6d6c6c;
$dark-gray: #a9a9a9;
$tundora: #4a4244;
$mahogany: #4E0606;
$mandys-pink: #f3bcc1;
$mischka: #dad2dd;
$biloba-flower: #b2a1ea;
$punch: #dc4333;
$tamarillo: #991613;
$silver-chalice: #acacac;
$vista-blue: #8fd6b4;
$white: #ffffff;
$wedgewood: #4e7f9e;
$powder-blue: #b0e0e6;
$firefly: #0e2a30;
$chathams: #175579;
$transparent-black: rgba(0, 0, 0, .2);
$transparent-white: rgba(255, 255, 255, .2);
$temptress: #3B000B;
$burgundy: #900020;

$link-blue: $havelock-blue;

/* Sensible color names as variables. */
:root {
    --color-first-blue: #{$first-blue};
    --color-first-red: #{$first-red};
    --color-first-gray: #{$first-gray};
    --color-first-black: #{$first-black};
    --color-first-purple: #{$first-purple};
    --color-first-green: #{$first-green};
    --color-first-orange: #{$first-orange};
    --color-first-light-blue: #{$first-light-blue};
    --color-first-light-gray: #{$first-light-gray};
    --color-first-ma-dark-blue: #{$first-ma-dark-blue};
    --color-first-ma-dark-gray: #{$first-ma-dark-gray};



    --color-body-background: #{$first-light-gray};
    --color-background: #{$white};

    --color-main-text: #{$first-black};
    --color-link: #{$first-blue};
    --color-link-hover: #{$first-light-blue};

    --color-primary: #{$first-blue};
    --color-primary-text: #{$white};
    --color-accent: #{$first-light-blue};

    --color-header-background: var(--color-primary);
    --color-header-text-color: var(--color-primary-text);

    --color-sidebar-background: var(--color-primary);
    --color-sidebar-text-color: var(--color-primary-text);
    --color-sidebar-link-hover: #{$first-light-gray};

    --color-transparent-black: #{$transparent-black};
    --color-transparent-white: #{$transparent-white};
    --color-drop-shadow: var(--color-transparent-black);

    --color-accent-border: #{$light-gray};
    --color-accent-spacer: #{$gallery};

    // Flash Messages
    --color-success-border: #{$eucalyptus};
    --color-success-background: #{$vista-blue};
    --color-warning-border: #{$amber};
    --color-warning-background: #{$cape-honey};
    --color-error-border: #{$punch};
    --color-error-background: #{$mandys-pink};
    --color-notice-border: #{$wedgewood};
    --color-notice-background: #{$powder-blue};
}

@media (prefers-color-scheme: dark) {
    :root {
        --color-body-background: #{$first-black};
        --color-background: #{$first-ma-dark-gray};

        --color-main-text: #{$first-light-gray};
        --color-link: #{$first-light-blue};
        --color-link-hover: #{$first-blue};

        --color-primary: #{$first-ma-dark-blue};
        --color-primary-text: #{$white};
        --color-accent: #{$first-blue};

        --color-accent-border: #{$tundora};
        --color-accent-spacer: #{$dove-gray};

        // Flash Messages
        --color-success-border: #{$bush};
        --color-success-background: #{$parsley};
        --color-warning-border: #{$dark-pumpkin};
        --color-warning-background: #{$meteor};
        --color-error-border: #{$mahogany};
        --color-error-background: #{$tamarillo};
        --color-notice-border: #{$firefly};
        --color-notice-background: #{$chathams};
    }
}
