table {
    display: block;
    max-width: -moz-fit-content;
    max-width: fit-content;
    overflow-x: auto;
    white-space: nowrap;
    
    border-collapse: collapse;
    
    tr {
        td,
        th {
            padding: .25em .5em;
            border: 1px solid var(--color-accent-border);
        }
        
        th {
            background: var(--color-primary);
        }

        &:nth-child(even) {
            background: var(--color-body-background);
        }

        &:hover {
            background-color: var(--color-accent-border);
        }
    }
}

.table-wrapper {
    max-width: 100%;
    overflow: auto;
}