label {
  font-size: 1rem;
}

.widget-required .widget-label::after {
  content: "*";
  padding-left: .1em;
  color: red;
}

#wrapper {
  width: 1000px;
  max-width: 90%;
  margin: 1em auto;
  box-shadow: 3px 3px 5px #ccc;
}

#main-content form input[type="submit"],
#main-content form input[type="button"] {
  width: 15em;
  max-width: 50%;
  font-size: 1.5em;
}

input[type="submit"],
input[type="button"],
button[type="submit"],
button[type="button"],
a.button,
a:link.button {
  position: relative;

  display: block;
  margin: 30px auto;
  padding: 0;

  overflow: hidden;

  border-width: 0;
  outline: none;
  border-radius: 2px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .6);

  background-color: var(--color-accent);
  color: var(--color-primary-text);

  transition: background-color .3s;
  padding: 12px 24px;
}

input[type="submit"]:hover,
input[type="submit"]:focus,
input[type="button"]:hover,
input[type="button"]:focus,
a.button:hover,
a:link.button:hover,
a.button:focus,
a:link.button:focus {
  background-color: var(--color-primary);
  color: var(--color-primary-text);
}

.button > * {
  position: relative;
}

.button span {
  display: block;
}
input[type="submit"]:before,
input[type="button"]:before,
.button:before
.button:before {
  content: "";

  position: absolute;
  top: 50%;
  left: 50%;

  display: block;
  width: 0;
  padding-top: 0;

  border-radius: 100%;

  background-color: rgba(236, 240, 241, .3);

  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
input[type="submit"]:active:before,
input[type="button"]:active:before,
.button:active:before {
  width: 120%;
  padding-top: 120%;

  transition: width .2s ease-out, padding-top .2s ease-out;
}