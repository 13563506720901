#main-wrapper {
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    @media (min-width: $breakpoint-tablet) {
        flex-direction: row;
    }

    #body-wrapper {
        overflow: auto;
        flex-grow: 1;

        #body-inner {
            margin: 1em;
            padding: 1em;
        }
    }
}

.row {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media (min-width: $breakpoint-tablet) {
        flex-direction: row;
    }
    
    .block {
        flex-grow: 1;
        margin: 1em;
        padding: 1em;
        max-width: 100%;

        @media (min-width: $breakpoint-tablet) {
            flex-basis: 400px;
            flex-direction: row;
        }
    }
}
