#region-sidebar {

    display: block;
    background-color: var(--color-sidebar-background);
    color: var(--color-sidebar-text-color);

    a,
    a:link {
        color: var(--color-sidebar-text-color);
        border-bottom: none;

        &:hover,
        &:active,
        &:focus {
            color: var(--color-sidebar-link-hover);
        }
    }

    box-shadow: 0 0 1em 0 var(--color-drop-shadow);
    transition: 3s fade;
    overflow-y: auto;

    z-index: 1;

    nav ul {
        li {
            position: relative;
            display: block;

            a,
            a:link {
                position: relative;
                padding: 1em 2em;
                width: 100%;
                display: block;
                border-bottom: 1px solid var(--color-transparent-black);

                &:hover {
                    background: var(--color-accent)
                }
            }
        }
    }

    @media (min-width: $breakpoint-tablet) {
        position: relative;
        display: block;
        max-width: 30%;
        width: 300px;
        min-width: 200px;
    }

}


#region-top-bar label#sidebar-control-action {
    width: 4em;
    height: 100%;
    position: relative;

    i {
        --ggs: 2;
        position: absolute;
        top: 50%;
        left: 40%;
    }

    @media (min-width: $breakpoint-tablet) {
        display: none;
    }
}

input#sidebar-control {
    display: none;
}

input#sidebar-control + #region-sidebar {
    position: absolute;
    top: 4em;
    left: 0;
    right: 0;
    bottom: 0;

    @media (min-width: $breakpoint-tablet) {
        position: relative;
        top: initial;
        left: initial;
        right: initial;
        bottom: initial;
        display: block;
        height: auto;
    }
}

input#sidebar-control:not(:checked) + #region-sidebar {
    display: none;
    @media (min-width: $breakpoint-tablet) {
        display: block;
        height: auto;
    }
}