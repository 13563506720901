nav {
    > .nav-control-action {
        @media (min-width: $breakpoint-tablet) {
            display: none;
        }
    }

    > input.nav-control {
        display: none;

        + ul {
            display: none;

            @media (min-width: $breakpoint-tablet) {
                display: block;
            }
        }

        &:checked + ul {
            display: block;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        a,
        a:link {
            text-decoration: none;
        }
    }
}
