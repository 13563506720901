@import '_variables.scss';
@import '_colors.scss';

@import '_nav.scss';

* {
    box-sizing: border-box;
}

body,
html {
  padding: 0;
  margin: 0;
  color: var(--color-main-text);
  height: 100%;
  overflow: hidden;
}
html {
  font: 16px/1.5 Verdana,Helvetica,Arial,sans-serif;
}
body {
  background: var(--color-body-background);
  display: flex;
  flex-direction: column;
}

a,
a:link {
  color: var(--color-link);

  text-decoration: none;
  border-bottom: 1px dotted var(--color-link);

  &:hover,
  &:active,
  &:focus {
    border-bottom: 1px solid var(--color-link-hover);
    color: var(--color-link-hover);
  }
}

blockquote {
  background: white;
  box-shadow: 0px 2px 5px lightgray;
  padding: 1em;
  margin: 1em;
}
blockquote *:first-child {
  margin-top: 0px;
}
blockquote.legal-text {
  font-size: .95em;
}
blockquote.legal-text h4 {
  text-align: center;
}
blockquote.legal-text h4,
blockquote.legal-text h5 {
  margin-bottom: .5em;
}
blockquote.legal-text h5 {
  margin-top: 1em;
}

#page-wrapper {
  flex-grow: 1;

  display: flex;
  flex-direction: column;
}

#body-wrapper {
    position: relative;
}
#body-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}
#body-inner {
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  background: var(--color-background);
}

.back-to-top {
  clear:both;
  float: right;
}

@import '_inputs.scss';
@import '_components.scss';
@import '_layout.scss';
