.box {
    box-shadow: .1em .1em .5em .2em var(--color-transparent-black);
    border-radius: .3em;

    margin-top: $gutter;

    & > * {
        padding: $standard-padding;
    }

    .box-header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        background: var(--color-primary);
        color: var(--color-primary-text);
        border-radius: .3em .3em 0 0;

        a {
            color: var(--color-primary-text);
            border-bottom: 1px dotted var(--color-primary-text);
        }

        .details {
            font-weight: bold;
            font-size: 1.2em;

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                font-size: 1em;
                padding: 0;
                margin: 0;
            }
        }
    }
}
