#region-top-bar {
    height: 4em;
    background-color: var(--color-header-background);
    box-shadow: 0 0 1em 0 var(--color-drop-shadow);
    z-index: 2;

    a,
    a:link {
        color: var(--color-header-text-color);
        border-bottom: none;

        &:hover,
        &:active,
        &:focus {
            color: var(--color-header-link-hover);
        }
    }

    nav {
        line-height: 4em;
        float: right;
        height: 100%;

        ul {

            li {
                display: inline-block;
                padding-left: 1em;
                padding-right: 1em;
            }
        }
    }

    nav#user-nav {
        min-width: 4em;
        position: relative;

        > .nav-control-action {
            display: block;
            width: 4em;
            height: 100%;

            i {
                --ggs: 1.5;
                position: absolute;
                top: 30%;
                left: 30%;
            }

            @media (min-width: $breakpoint-tablet) {
                display: none;
            }
        }

        > input.nav-control {
            &:checked + ul {
                background: var(--color-accent);
                position: absolute;
                top: 4em;
                right: 0;
                min-width: 15em;
                max-width: 100%;

                display: flex;
                flex-direction: column;

                li {
                    white-space: nowrap;
                    display: block;
                }
            }
        }
    }

    #top-bar-wrapper {
        height: 4em;
        display: flex;
    }

    #logo {
        display: none;
        @media (min-width: $breakpoint-mobile) {
            display: initial;
        }
        
        > img {
            height: 2em;
            margin: 1em;
        }
    }

    #app-name {
        line-height: 4rem;
        flex-grow: 1;
    }
}
