/* Display Sizing. */
$main-width: 1200px;
$breakpoint-tablet: 900px;
$breakpoint-mobile: 600px;

/* Layout Structure */
$standard-padding: 1em;
$double-padding: 2 * $standard-padding;
$half-padding: $standard-padding / 2;
$gutter: $standard-padding;
$half-gutter: $gutter / 2;

$logo-width: 16 * $gutter;
$logo-padding: $logo-width / 10;

:root {
    --main-width: #{$main-width};
    --breakpoint-tablet: #{$main-width};
}
